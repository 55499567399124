import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

type ArticleParam = {
  to: string,
  image: any,
  title: string,
  date: string,
  description: string
}

const BlogPost: React.VFC<ArticleParam> = ({ to, image, title, date, description }) => {
  return (
    <div className='flex flex-col md:flex-row items-center gap-4 lg:gap-6'>
      <Link to={to} className='group w-full md:w-24 lg:w-40 h-56 md:h-24 lg:h-40 block shrink-0 bg-slate-100 overflow-hidden rounded-lg shadow-lg relative'>
        <GatsbyImage
          image={image}
          alt=""
          className="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
        />
      </Link>
      <div className='flex flex-col gap-1'>
        <span className='text-slate-400 text-sm'>Posted: {date}</span>
        <h2 className='text-xl font-bold leading-none'>
          <Link to={to} className="hover:text-slate-400 active:text-slate-500 transition duration-100">
            {title}
          </Link>
        </h2>
        {
          description &&
          <p className="text-slate-400">
            {description}
          </p>
        }
        <div>
          <Link to={to} className="text-slate-400 text-sm hover:text-slate-600 active:font-semibold transition duration-100">
            続きを読む
          </Link>
        </div>
      </div>
    </div>
  )
}

const BlogPage: React.VFC<any> = ({ data }) => {
  return (
    <Layout pageTitle='Blog Posts'>
      <div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8 sm:gap-12 xl:gap-16">
        {
          data.allMdx.nodes.map((node: any) => (
            <BlogPost
              to={`/blog/${node.slug}`}
              image={getImage(node.frontmatter.hero_image)}
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              description={node.excerpt}
            />
          ))
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        excerpt(truncate: true, pruneLength: 40)
      }
    }
  }
`

export default BlogPage
